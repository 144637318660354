import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import logo from "./logo.svg"

const LogoWrap = styled.div`
  margin: 30px 0;
//   flex: 0 1 100px;

  @media (max-width: 768px) and (orientation: landscape) {
    // flex: 0 1 80px;
  }
`
const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "gatsby-icon" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 50, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LogoWrap as={Link} to="/">
      <img src={logo} alt="logo" data-sal="slide-up" data-sal-delay="200" data-sal-duration="700"/>
    </LogoWrap>
  )
}

export default Logo