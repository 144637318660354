import { graphql } from "gatsby"
import React from "react"
import BlogList from "../components/blog/blogList"
// import Layout from "../components/layout"
import Layout from "../components/layout_dpcgc"
import Navbar from "../Navbar/Navbar"

export const query = graphql`
  query blogs {
    allStrapiArticle(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          author
          title
          category {
            name
          }
          content
          image {
            publicURL
          }
          strapiId
          published_at
        }
      }
    }
  }
`

export default ({
  data: {
    allStrapiArticle: { edges: articles },
  },
}) => {
  // const firstArticle = articles[0].node
  // const restArticles = articles.filter((article, index) => index > 0)
  return (
    <Layout >
      <Navbar />
      <div className="u-padding-top-xl"></div>
      <React.Fragment>
        {articles.length > 0 && (
          <React.Fragment>
            {/* This is temporariely commented | Please uncomment to view articles */}
            {/* <BlogList articles={articles} /> */}
          </React.Fragment>
        )}
      </React.Fragment>
    </Layout>
  )
}
